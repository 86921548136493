import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { registerUserFCM } from "./firebase";
import ScrollToTop from "./utils/ScrollToTop";

import App from "./App";
import store from "./redux";
import "./i18n";
import i18n from "i18next";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

import { internalIPs } from "./internalIPs";
export const msalInstance = new PublicClientApplication(msalConfig);

async function isInternalNetwork() {
  let clientIp = "";
  //QUELLA DA DEPLOYARE POI
  await fetch(process.env.REACT_APP_SHOW_MY_IP) //<-- In locale da CORS
    //await fetch("https://api.ipify.org?format=json")
    .then((response) => response.json())
    .then((data) => (clientIp = data.ip))
    .catch((error) => console.log(error));

  var ipRangeCheck = require("ip-range-check");

  return ipRangeCheck(clientIp, internalIPs);
}

isInternalNetwork().then((isInternalIp) => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const orderedAccounts = [...msalInstance.getAllAccounts()].sort(
      (a, b) => b.idTokenClaims.auth_time - a.idTokenClaims.auth_time
    );
    msalInstance.setActiveAccount(orderedAccounts[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  //registerUserFCM();
  const LoadDynamicMediaVideoViewer = () => {
    const scriptId = "DynamicMediaVideoViewer";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "https://ferrari.scene7.com/s7viewers/html5/js/SmartCropVideoViewer.js";
      script.async = true;
      script.id = scriptId;
      script.onload = () => {
        //we wait untill is loaded
      };
      document.body.appendChild(script);
    }
  };

  const LoadDynatrace = () => {
    const scriptId = "DYNATRACE-SCRIPT";
    const existingScript = document.getElementById(scriptId);
    if (process.env.REACT_APP_ENV === "production" && !existingScript) {
      const script = document.createElement("script");
      script.src = "/dynatrace.js";
      script.id = scriptId;
      script.onload = () => {
        //we wait untill is loaded
      };
      document.body.appendChild(script);
    }
  };

  const LoadGA4Script = () => {
    const scriptId = "GA4-SCRIPT";
    const existingScript = document.getElementById(scriptId);
    if (process.env.REACT_APP_ENV === "production" && !existingScript) {
      const script = document.createElement("script");
      script.src = "/ga4.js";
      script.id = scriptId;
      script.onload = () => {
        //we wait untill is loaded
      };
      document.body.appendChild(script);
    }
  };

  const LoadGA4NoScript = () => {
    const noscriptId = "GA4-NOSCRIPT";
    const existingScript = document.getElementById(noscriptId);
    if (process.env.REACT_APP_ENV === "production" && !existingScript) {
      var noscript = document.createElement("noscript");
      noscript.id = noscriptId;
      var iframe = document.createElement("iframe");
      iframe.setAttribute(
        "src",
        "https://www.googletagmanager.com/ns.html?id=GTM-NSR45QB"
      );
      iframe.setAttribute("height", "0");
      iframe.setAttribute("width", "0");
      iframe.setAttribute("style", "display:none;visibility:hidden");
      noscript.appendChild(iframe);
      noscript.onload = () => {
        //we wait untill is loaded
      };
      document.head.appendChild(noscript);
    }
  };

  const LoadGooglePlaces = (lang) => {
    const scriptId = "GooglePlaces";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBizoLpbu_ESqkb1v5BrNpmE0WwVcB9EW4&loading=async&libraries=places&language=${lang}`;

      script.onload = () => {
        //we wait untill is loaded
      };
      document.body.appendChild(script);
    }
  };

  const SetInitLang = () => {
    let lang;
    switch (i18n.language) {
      case "it":
      case "en":
        lang = i18n.language;
        break;
      default:
        lang = "it";
        break;
    }
    return lang;
  };

  LoadDynamicMediaVideoViewer();
  LoadGA4Script();
  LoadGA4NoScript();
  const language = SetInitLang();
  LoadGooglePlaces(language);
  LoadDynatrace();
  const root = createRoot(document.getElementById("root"));

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <ScrollToTop isInternal={isInternalIp} />
          <App pca={msalInstance} lang={language} isInternal={isInternalIp} />
        </MsalProvider>
      </Provider>
    </BrowserRouter>
  );
});
