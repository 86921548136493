import React, { useEffect, useRef } from "react";
import { VideoContainer } from "./style";

//Vigna: ferraristage/f163ab14-0ce6-44cb-943e-23e6ac560ccf-2-AVS
//40 anni: ferraristage/bb4fe7ca-6c12-4267-b964-7c75ec3e2f5b-2-AVS
//Macchina: ferraristage/sample-3-AVS

//  posterimage: "https://www.altrasoluzione.com/images/blog/siti-per-scaricare-immagini-gratis-legalmente/immagini-gratis.jpg",
const SmartCropVideoViewer = ({ data, id }) => {
  const videoContainerRef = useRef(null);

  useEffect(() => {
    const s7smartcropvideoviewer = new window.s7viewers.SmartCropVideoViewer({
      containerId: videoContainerRef.current.id,
      params: {
        serverurl: data.serverUrl,
        contenturl: data.contentUrl,
        config: `${data.environment}/SmartCropVideo`,
        videoserverurl: data.videoServerUrl,
        asset: data.scene7FileAvsPath,
      },
    });
    s7smartcropvideoviewer.init();
  }, []);

  return (
    <VideoContainer
      id={`s7smartcropvideo_div-${id}`}
      ref={videoContainerRef}
    ></VideoContainer>
  );
};

export default SmartCropVideoViewer;
