import styled from "styled-components";

export const Wrapper = styled.div`
  ${(props) =>
    props.fullScreen === true
      ? `position: fixed;
    width: 95vw;
    height: 100vh;
    flex-grow: 1;
    z-index: 10;
    `
      : `position: relative;
    width: 100%;
    height: unset;
    flex-grow: unset;
    z-index: 2;
    `};
  top: 0;
  left: 0;
  bottom: 0;

  @media (max-width: 1279px) {
    width: 100%;
    .fullscreenSvg {
      ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
    }
    :hover {
      .fullscreenSvg {
        opacity: 1;
      }
    }
    .carousel-slider {
      ${(props) =>
        props.fullScreen === true ? `height: 100vh;` : `height: unset`};
    }
    .carousel {
      background: black;
      .control-next,
      .control-prev {
        z-index: 3;
        ${(props) =>
          props.firstHover === true ? `opacity: 0;` : `opacity: 1;`};
      }
      .slide {
        ${(props) =>
          props.firstHover === true ? `opacity: 0 !important;` : `opacity: 1;`};
      }
      .selected {
        ${(props) =>
          props.firstHover === true ? `opacity: 1 !important;` : `opacity: 1;`};
      }
    }
    .carousel .slide {
      div {
        ${(props) =>
          props.fullScreen === true ? `height:100vh;` : `height: unset`};
        img {
          ${(props) =>
            props.fullScreen === true
              ? `height: 100%;width: -webkit-fill-available;max-height: -webkit-fill-available;`
              : ` height: 300px;`};

          object-fit: contain;
        }
      }
    }
    .carousel .carousel-dot {
      background: var(--main-color) !important;
    }
  }
  @media (min-width: 1279px) {
    .fullscreenSvg {
      ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
    }
    :hover {
      .fullscreenSvg {
        opacity: 1;
      }
    }
    ${(props) =>
      props.fullScreen === true
        ? `position: fixed;width: 99.1vw;`
        : `position: relative;width: 100%;`};

    .carousel-slider {
      ${(props) =>
        props.fullScreen === true ? `height: 100vh;` : `height: unset`};
    }
    .carousel {
      background: black;
      .control-next,
      .control-prev {
        z-index: 3;
        ${(props) =>
          props.firstHover === true ? `opacity: 0;` : `opacity: 1;`};
      }
      .slide {
        ${(props) =>
          props.firstHover === true ? `opacity: 0 !important;` : `opacity: 1;`};
      }
      .selected {
        ${(props) =>
          props.firstHover === true ? `opacity: 1 !important;` : `opacity: 1;`};
      }
    }

    .carousel .slide {
      div {
        ${(props) =>
          props.fullScreen === true ? `height:100vh` : `height: unset`};
        img {
          ${(props) =>
            props.fullScreen === true
              ? `height: -webkit-fill-available`
              : `height: 556px`};
          object-fit: contain;
        }
      }
    }
  }
`;

export const SvgFullScreen = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 19.1 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  position: absolute;
  right: 28px;
  z-index: 4;
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${(props) =>
    props.fullScreen === true ? `bottom: 70px;` : `bottom: 120px;`};
  ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
  @media (min-width: 1279px) {
    ${(props) => (props.firstHover === true ? `opacity: 0;` : `opacity: 1;`)};
    position: absolute;
    right: 28px;
    z-index: 11;
    width: 20px;
    height: 20px;
  }
`;

export const SvgFullScreenPath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M18.9,0.2l0,8.6l-3.3-3.3l-3.3,3.3L9.9,6.4l3.3-3.3l-3-3L18.9,0.2z M9.9,11.2L5.9,15l2.9,2.8l-8.7,0l0-8.6 l3.4,3.4l3.9-3.9L9.9,11.2z",
})`
  fill: #d4d2d2;
  pointer-events: cursor;
`;

export const SvgExitFullScreenPath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M8.3,9.3l0,7.7l-3.1-2.9l-3,2.9L0,14.9L3,12L0.3,9.3L8.3,9.3z M18,2.1l-3.6,3.4l2.7,2.5l-8,0l0-7.6l3.1,3 L15.8,0L18,2.1z",
})`
  fill: #d4d2d2;
  pointer-events: cursor;
`;
