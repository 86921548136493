import styled from "styled-components";

//wrapper
export const Wrapper = styled.div`
  background: black;
  margin: 25px 0;
  padding-bottom: 5px;
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
`;
export const CurrentTime = styled.p`
  font-size: 10px;
  color: white;
  margin-left: 5px;
`;
export const Duration = styled.p`
  font-size: 10px;
  color: white;
  margin-right: 5px;
`;
export const InnerContainer = styled.div`
  display: block;
  height: 50px;
`;
//container play, pause and text
export const PlayContainer = styled.div`
  display: flex;
  gap: 15px;
  color: white;
  z-index: 1;
`;

//text on audio
export const PlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AltText = styled.p`
  font-family: "openSans-medium", Arial;
  height: 18px;
  font-size: 16px;
  margin-bottom: 5px;
`;
export const Caption = styled.p`
  color: #fff;
  font-family: "openSans-medium", Arial;
  height: 18px;
  font-size: 14px;
  margin-top: 0;
`;

export const ButtonPlay = styled.button`
  border: none;
  width: 50px;
  height: 50px;
  margin-top: 12px;
`;

//play and pause
export const SvgPlay = styled.svg.attrs({
  width: "24",
  height: "24",
  viewBox: "0 0 12.8 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
  fill: #d4d2d2;
  :hover {
    fill: #da291c !important;
  }
`;
export const SvgPause = styled.svg.attrs({
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 12.8 15",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
  fill: #d4d2d2;
  :hover {
    fill: #da291c !important;
  }
`;
export const SvgPlayPath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M0.2,14c0-0.7,0-12.5,0-13c0-0.6,0.6-1.1,1.2-0.7c0.5,0.3,9.9,6,10.8,6.5c0.6,0.3,0.5,1.1,0,1.4 c-0.6,0.4-10.1,6.1-10.8,6.5C0.9,15,0.2,14.7,0.2,14z",
})``;
export const SvgPausePath = styled.path.attrs({
  fillRule: "evenodd",
  d: "M5.2,0v14H8V0H5.2z M0,14h2.8V0H0V11z",
})``;
