import { Carousel } from "react-responsive-carousel";
import {
  Wrapper,
  DivSelectedDots,
  ImgDots,
  DivUnselectedDots,
  SvgArrowRight,
  SvgArrowRightPath,
  SvgArrowLeft,
  SvgArrowLeftPath,
} from "./style";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";

function CarouselAlternative({
  data,
  children,
  centerMode = false,
  fullScreen,
  firstHover,
}) {
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  let hideArrows = false;

  //dimensione contenuto slider
  const sliderContent = data?.items?.length * 73 - 13;

  //dimensione slider
  let sliderWidth = parseInt(
    document.querySelector(".containerCarousel")?.offsetWidth * 0.8
  );
  //px scrollabili
  let totalScrollable = sliderContent - sliderWidth;

  //ammontare dello scroll in base al numero di item
  let scrollOnClick =
    data?.items?.length < 11
      ? (sliderContent - sliderWidth) / 1
      : data?.items?.length < 20
      ? (sliderContent - sliderWidth) / 2
      : (sliderContent - sliderWidth) / 4;

  //scroll slider sx
  const handleScrollLeft = () => {
    sliderWidth = parseInt(
      document.querySelector(".containerCarousel")?.offsetWidth * 0.8
    );
    totalScrollable = sliderContent - sliderWidth;
    setHideRightArrow(false);
    setScrollAmount(Math.abs(scrollAmount) - scrollOnClick);
    document.querySelector(".control-dots").scrollTo({
      top: 0,
      left: scrollAmount - scrollOnClick,
      behavior: "smooth",
    });
    if (scrollAmount - scrollOnClick <= 0) {
      setHideLeftArrow(true);
      return;
    }
  };

  //scroll slider dx
  const handleScrollRight = () => {
    sliderWidth = parseInt(
      document.querySelector(".containerCarousel")?.offsetWidth * 0.8
    );
    totalScrollable = sliderContent - sliderWidth;

    setHideLeftArrow(false);
    setScrollAmount(Math.abs(scrollAmount) + scrollOnClick);
    document.querySelector(".control-dots").scrollTo({
      top: 0,
      left: scrollAmount + scrollOnClick,
      behavior: "smooth",
    });
    if (totalScrollable - (scrollAmount + scrollOnClick) <= 0) {
      setHideRightArrow(true);
      return;
    }
  };

  //window Resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      sliderWidth = parseInt(
        document.querySelector(".containerCarousel")?.offsetWidth * 0.8
      );
      totalScrollable = sliderContent - sliderWidth;
      hideArrows = sliderContent <= sliderWidth;
      if (totalScrollable <= 0) {
        hideArrows = sliderContent <= sliderWidth;
        setHideLeftArrow(true);
        setHideRightArrow(true);
      } else {
        sliderWidth = parseInt(
          document.querySelector(".containerCarousel")?.offsetWidth * 0.8
        );
        totalScrollable = sliderContent - sliderWidth;
        if (document.querySelector(".control-dots")?.scrollLeft > 0) {
          setHideLeftArrow(false);
        } else {
          document.querySelector(".control-dots").scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
          setHideLeftArrow(true);
        }
        if (
          sliderContent > sliderWidth &&
          sliderContent <= sliderWidth + scrollAmount
        ) {
          document.querySelector(".control-dots").scrollTo({
            top: 0,
            left: scrollAmount + 240,
            behavior: "instant",
          });
          setHideRightArrow(true);
        } else {
          setHideRightArrow(false);
        }
      }
    });
  }, [scrollAmount, totalScrollable, isMobile, fullScreen]);

  //initialState
  useEffect(() => {
    sliderWidth = parseInt(
      document.querySelector(".containerCarousel")?.offsetWidth * 0.8
    );
    totalScrollable = sliderContent - sliderWidth;
    hideArrows = sliderContent <= sliderWidth;
  }, []);

  useEffect(() => {
    sliderWidth = parseInt(
      document.querySelector(".containerCarousel")?.offsetWidth * 0.8
    );
    totalScrollable = sliderContent - sliderWidth;
    if (totalScrollable <= 0) {
      hideArrows = sliderContent <= sliderWidth;
      setHideLeftArrow(true);
      setHideRightArrow(true);
    } else {
      sliderWidth = parseInt(
        document.querySelector(".containerCarousel")?.offsetWidth * 0.8
      );
      totalScrollable = sliderContent - sliderWidth;
      if (scrollAmount === 0) {
        setHideLeftArrow(true);
      }
      if (scrollAmount > 0) {
        setHideLeftArrow(false);
      }
      if (totalScrollable - scrollAmount <= 0) {
        setHideRightArrow(true);
      }
      if (totalScrollable - scrollAmount >= 0) {
        setHideRightArrow(false);
      }
    }
  }, [window]);

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    return isSelected ? (
      <DivSelectedDots
        onClick={onClickHandler}
        index={index}
        value={index}
        key={index}
        label={label}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      >
        <ImgDots src={data?.items?.[index]?.fileReference} />
      </DivSelectedDots>
    ) : (
      <DivUnselectedDots
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      >
        <ImgDots
          className="carousel-dot"
          src={data?.items?.[index]?.fileReference}
        />
      </DivUnselectedDots>
    );
  };
  const loader = useRef(null);
  return (
    <Wrapper
      isMobile={isMobile}
      hideLeftArrow={hideLeftArrow}
      hideRightArrow={hideRightArrow}
      hideArrows={hideArrows}
      ref={loader}
      firstHover={firstHover}
      fullScreen={fullScreen}
    >
      {!isMobile && (
        <>
          <SvgArrowRight
            className="rightArrowSlider"
            onClick={handleScrollRight}
          >
            <SvgArrowRightPath />
          </SvgArrowRight>
          <SvgArrowLeft className="leftArrowSlider" onClick={handleScrollLeft}>
            <SvgArrowLeftPath />
          </SvgArrowLeft>
        </>
      )}
      <Carousel
        centerMode={centerMode}
        showStatus={true}
        showThumbs={false}
        centerSlidePercentage={65}
        animationHandler={"fade"}
        renderIndicator={renderIndicator}
      >
        {children}
      </Carousel>
    </Wrapper>
  );
}

export default CarouselAlternative;
