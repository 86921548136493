import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// style
import { Container } from "./style";

function NewsImage({ data }) {
  const [smartCrop, setSmartCrop] = useState("Large");
  const [preSet, setPreSet] = useState("DEFAULT");

  const handleResize = () => {
    if (window.innerWidth >= 1280) {
      setSmartCrop("Large");
      setPreSet("DEFAULT");
    } else if (window.innerWidth >= 760) {
      setSmartCrop("Medium");
      setPreSet("GRAYSCALE");
    } else {
      setSmartCrop("Small");
      setPreSet("ANDROID");
    }
  };

  useEffect(() => {
    handleResize(); // Set initial size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const url = `${data.fileReference}:${smartCrop}?$${preSet}$`;

  return (
    <Container>
      <img src={url} alt={data.alt} />
    </Container>
  );
}

NewsImage.propTypes = {
  data: PropTypes.exact({
    isDecorative: PropTypes.string,
    linkTarget: PropTypes.string,
    titleValueFromDAM: PropTypes.string,
    imageFromPageImage: PropTypes.string,
    fileReference: PropTypes.string,
    alt: PropTypes.string,
    displayPopupTitle: PropTypes.string,
    position: PropTypes.string,
    altValueFromDAM: PropTypes.string,
  }),
};

export default NewsImage;
