// components
import React, { useEffect, useState } from "react";
import CarouselAlternative from "../../carouselAlternative/CarouselAlternative";
import useMediaQuery from "../../../../hooks/useMediaQuery";
// style
import {
  SvgExitFullScreenPath,
  SvgFullScreen,
  SvgFullScreenPath,
  Wrapper,
} from "./style";

function MainCarouselAlternative({ data }) {
  const [fullScreen, setFullscreen] = useState(false);
  const [firstHover, setFirstHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const handleFullScreen = () => {
    setFullscreen(!fullScreen);
    fullScreen
      ? (document.body.style.overflow = "initial")
      : (document.body.style.overflow = "hidden");
  };
  useEffect(() => {
    fullScreen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "initial");
    fullScreen &&
      document
        .querySelector(".carousel-root")
        ?.setAttribute("style", "width: 100vw");
    !fullScreen &&
      document
        .querySelector(".carousel-root")
        ?.setAttribute("style", "width: 100%");
  }, [isDesktop, fullScreen]);
  return (
    data && (
      <Wrapper
        firstHover={firstHover}
        onMouseEnter={() => setFirstHover(true)}
        fullScreen={fullScreen}
        className="containerCarousel"
      >
        <SvgFullScreen
          className="fullscreenSvg"
          fullScreen={fullScreen}
          onClick={handleFullScreen}
        >
          {fullScreen ? (
            <SvgExitFullScreenPath className="fullscreenSvgPath" />
          ) : (
            <SvgFullScreenPath className="fullscreenSvgPath" />
          )}
        </SvgFullScreen>
        <CarouselAlternative
          data={data}
          fullScreen={fullScreen}
          firstHover={firstHover}
        >
          {data?.items?.map((item, ind) => (
            <div key={`img- ${ind}`}>
              {isDesktop ? (
                <img className="imgCarousel" src={item.fileReference} />
              ) : (
                <img className="imgCarousel" src={item.fileReference} />
              )}
            </div>
          ))}
        </CarouselAlternative>
      </Wrapper>
    )
  );
}

export default MainCarouselAlternative;
