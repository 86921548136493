import React from "react";
import PropTypes from "prop-types";
// components
import NewsDocContainer from "./newsDocContainer/NewsDocContainer";
import CardWrapper from "./cardWrapper/CardWrapper";
import InfoBox from "./infoBox/InfoBox";
// style
import { Container } from "./style";
import Recap from "./recapBox/Recap";
import BulletPointInfo from "./bulletPointInfo/BulletPointInfo";
import ImageTitle from "./imageTitle/ImageTitle";
import MainCarousel from "../../home/mainCarousel/MainCarousel";
import SmartCropVideoViewer from "../../smartCropVideoViewer/smartCropVideoViewer";
import { AudioPlayer } from "../../audioWave/AudioPlayer";

function RightColumnContainer({ rightColumnComponents }) {
  const componentsMapping = {
    "fi-cafeteria-cards": CardWrapper,
    "fi-cafeteria-recap": InfoBox,
    "fi-contratto-documents": NewsDocContainer,
    "fi-welfare/fi-welfare-recap": Recap,
    "fi-welfare/fi-welfare-info": BulletPointInfo,
    "fi-welfare/fi-welfare-title-image": ImageTitle,
    "fi-carousel": MainCarousel,
    "fi-video": SmartCropVideoViewer,
    "fi-audio": AudioPlayer,
  };

  return (
    <Container>
      {React.Children.toArray(
        rightColumnComponents?.map((element, idx) => {
          const ReactGenericComponent = componentsMapping[element.resourceType];
          return (
            ReactGenericComponent && (
              <ReactGenericComponent
                id={idx}
                data={element.data}
              ></ReactGenericComponent>
            )
          );
        })
      )}
    </Container>
  );
}

RightColumnContainer.propTypes = {
  rightColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default RightColumnContainer;
