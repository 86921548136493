import { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import {
  AltText,
  InnerContainer,
  Caption,
  ButtonPlay,
  SvgPlay,
  SvgPlayPath,
  SvgPausePath,
  PlayContainer,
  PlayTextContainer,
  SvgPause,
  Wrapper,
  TimerContainer,
  CurrentTime,
  Duration,
} from "./style";

export function AudioPlayer({ data }) {
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [isInside, setIsInside] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState();

  const converTimeToString = (timeToConvert) => {
    let totSec = Math.floor(timeToConvert % 60)
      .toString()
      .padStart(2, "0");
    let totMin = Math.floor((timeToConvert / 60) % 60)
      .toString()
      .padStart(2, "0");
    return `${totMin}:${totSec}`;
  };

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      cursorWidth: 0,
      barWidth: 3,
      barHeight: 2,
      barAlign: "bottom",
      waveColor: "white",
      progressColor: "#da291c",
      height: 45,
    });
    waveSurfer.load(data?.fileReference);
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
      setDuration(converTimeToString(waveSurfer.getDuration()));
    });

    waveSurfer.on("audioprocess", () => {
      if (waveSurfer.isPlaying()) {
        setCurrentTime(converTimeToString(waveSurfer.getCurrentTime()));
      }
    });

    waveSurfer.on("click", () => {
      setCurrentTime(converTimeToString(waveSurfer.getCurrentTime()));
    });
    return () => {
      waveSurfer.destroy();
    };
  }, [data?.fileReference]);

  return (
    <>
      <Wrapper
        onMouseEnter={() => setIsInside(true)}
        onMouseLeave={() => setIsInside(false)}
      >
        <InnerContainer>
          {isInside && (
            <PlayContainer>
              <ButtonPlay
                onClick={() => {
                  waveSurferRef.current.playPause();
                  toggleIsPlaying(waveSurferRef.current.isPlaying());
                }}
              >
                {/* <img
                  aria-label={`${data?.altText} - ${data?.caption}`}
                  aria-roledescription="img"
                /> */}
                {isPlaying ? (
                  <SvgPause>
                    <SvgPausePath />
                  </SvgPause>
                ) : (
                  <SvgPlay>
                    <SvgPlayPath />
                  </SvgPlay>
                )}
              </ButtonPlay>
              <PlayTextContainer>
                <AltText>{data?.altText}</AltText>
                <Caption>{data?.caption}</Caption>
              </PlayTextContainer>
            </PlayContainer>
          )}
        </InnerContainer>
        <div
          style={{
            cursor: "pointer",
          }}
          ref={containerRef}
        />
        <TimerContainer>
          <CurrentTime>{currentTime}</CurrentTime>
          <Duration>{duration}</Duration>
        </TimerContainer>
      </Wrapper>
    </>
  );
}
