// components
import React from "react";
import MainCarouselAlternative from "../mainCarouselAlternative/MainCarouselAlternative";
import CarouselClassic from "../carouselClassic/CarouselChoice";

function MainCarousel({ data }) {
  return data?.isImported ? (
    <MainCarouselAlternative data={data} />
  ) : (
    <CarouselClassic data={data} />
  );
}

export default MainCarousel;
